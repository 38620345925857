import { language } from './utils'
const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '', hostname: '' }
export const backUrl = (location().hostname === 'localhost') ? 'https://back.smileybox.fr' : 'https://back.smileybox.fr'


export function getProducts() {
  return fetch(`${backUrl}/products`).then(res => res.json()).then(products => products.filter(p => p.locale.language === language()))
}

export function getStocks() {
  return fetch(`${backUrl}/stocks?locale=1`).then(res => res.json())
}

export function findEvent(id, token) {
  window.location = `https://party.wizito.com${location().pathname}`
  return fetch(`${backUrl}/events/${id}`, { headers: { Authorization: token } })
    .then(res => res.json())
    .then(res => (res.locale.language === 'fr' && location().origin !== 'https://smiley-box.com') ||
      (res.locale.language === 'en' && location().origin !== 'https://party-london.wizito.com') ? { ...res, error: "Fail to fetch", message: "404 Page Not Found" } : res)
}

export function createEvent(data) {
  return fetch(`${backUrl}/events`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...data, locale: language() }) })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function updateEvent(event, data) {
  return fetch(`${backUrl}/events/${event.id}`, { method: 'PUT', headers: { Authorization: event.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function applyPromo(event, promo) {
  return fetch(`${backUrl}/events/${event.id}/promo/${promo}`, { method: 'PUT', headers: { Authorization: event.token } })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function closeInvoice(event) {
  return fetch(`${backUrl}/events/${event.id}/finalize`, { method: 'POST', headers: { Authorization: event.token } })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function settleInvoice(event) {
  return fetch(`${backUrl}/events/${event.id}/settle`, { method: 'PUT', headers: { Authorization: event.token } })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function chargeInvoice(event, amount) {
  return fetch(`${backUrl}/events/${event.id}/advance/${amount}`, { method: 'POST', headers: { Authorization: event.token } })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function refreshInvoice(event) {
  return fetch(`${backUrl}/events/${event.id}/refresh`, { method: 'PUT', headers: { Authorization: event.token } })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function refreshCusto(event) {
  return fetch(`${backUrl}/events/${event.id}/refreshCusto`, { method: 'PUT', headers: { Authorization: event.token } })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function cancelEvent(event) {
  return fetch(`${backUrl}/events/${event.id}`, { method: 'DELETE', headers: { Authorization: event.token } })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function findPickup(country, zip) {
  return fetch(`https://mondial-relay.wizito.com/pickup/${country.toLowerCase()}/${zip}`)
    .then(res => res.json())
}

export function findDealer(id, token) {
  return fetch(`${backUrl}/dealers/${id}`, { headers: { Authorization: token } }).then(res => res.json())
}

export function updateDealer(dealer, data) {
  return fetch(`${backUrl}/dealers/${dealer.id}`, { method: 'PUT', headers: { Authorization: dealer.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function updateCard(dealer, card) {
  return fetch(`${backUrl}/dealers/${dealer.id}/card`, { method: 'PUT', headers: { Authorization: dealer.token, 'Content-Type': 'application/json' }, body: JSON.stringify({ source: card }) })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function removeCards(dealer) {
  return fetch(`${backUrl}/dealers/${dealer.id}/card`, { method: 'DELETE', headers: { Authorization: dealer.token, 'Content-Type': 'application/json' } })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function createProEvent(dealer, data) {
  return fetch(`${backUrl}/dealers/${dealer.id}/event`, { method: 'POST', headers: { Authorization: dealer.token, 'Content-Type': 'application/json' }, body: JSON.stringify({ data }) })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}

export function contactDealer(dealer, data) {
  console.log(dealer, data)
  return fetch(`${backUrl}/dealers/${dealer.id}/contact`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) })
    .then(res => res.json())
    .then(res => res.error ? Promise.reject(res.message) : res)
}